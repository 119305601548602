import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IndustriesInnerIntro from "@containers/industries-inner-intro/intro-section";
import VoiceProcess from "@containers/voiceover/strategy";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import CtaArea from "@containers/cta/layout-02";
import HowWeWork from "@containers/global/how-we-work";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import QuoteForm from "@containers/translation-quote-form";

const ManufacturingTranslationServicesPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Turnkey Manufacturing Translation Services"
        description="We offer high quality Translation and Localization Services for the manufacturing industry. Contact us today for more information."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
   
      <main className="site-wrapper-reveal">
        <PageHeader data={content["manufacturing-translation-page-header-section"]} />
        <QuoteForm />
        <PartnerArea data={content["manufacturing-translation-logo"]} />
        <FeatureGrid data={content["manufacturing-translation-features"]} />
        <IndustriesInnerIntro data={content["manufacturing-introsection-body"]} />
        <VoiceProcess data={content["manufacturing-process-body"]} />
        <CtaArea layout={2} data={content["manufacturing-cta-data"]} />
        <IntroAreaReverse data={content["manufacturing-solutions-body"]} />
        <HowWeWork layout={4} data={content["howwework"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};
export const query = graphql`
  query ManufacturingTranslationServicesPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "manufacturing-translation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

ManufacturingTranslationServicesPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ManufacturingTranslationServicesPage;
